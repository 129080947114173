import * as actionTypes from './actionTypes';
import reducerRegistry from '../../store/reducerRegistry.js';

// 从localstorage获取菜单
var menustr = localStorage.getItem('menu');
let menus = [];
if (menustr !== undefined && menustr !== null && menustr.length > 0 && menustr !== 'undefined') {
    menus = JSON.parse(menustr);
}
const reducerName = "menuReducer";

const reducer = (state, action) => {
    let newMenus;
    switch (action.type) {
        // 选中菜单
        case actionTypes.Common_Menu_SelectMenu:
            newMenus = state.menus.map(menu => copySelectMenu(menu, action.data.id));
            return { menus: newMenus };

        // 收拢所有菜单
        case actionTypes.Common_Menu_CollapseAll:
            newMenus = state.menus.map(menu => copyCollapseAllMenus(menu));
            return { menus: newMenus };

        // 菜单展开与收拢之间的切换
        case actionTypes.Common_Menu_ToggleExpand:
            newMenus = state.menus.map(menu => copyToggleExpand(menu, action.data.id));
            return { menus: newMenus };

        // 根据浏览器路径设置菜单当前选中项
        case actionTypes.Common_Menu_PathChange:
            let id = getIdByPath(state.menus, action.data.path);
            if (id === 0) {
                id = 1;       // 默认定位到第一个菜单
            }
            newMenus = state.menus.map(menu => copyPathChangeMenu(menu, id));
            expandParentMenu(newMenus, newMenus, id);
            return { menus: newMenus };

        case actionTypes.Common_Menu_Init:
            if (state.menus === null || state.menus.length === 0) {
                // 初始化菜单数据
                return { menus: menus };
            }
            return state;
        default:
            if (state === undefined || state === null || state.menus === null || state.menus.length === 0) {
                // 初始化菜单数据
                return { menus: menus };
            }
            return state;
    }
}

// 动态注入store
reducerRegistry.register(reducerName, reducer);

export default reducer;

// 复制并处理展开/收拢菜单逻辑, 参数id表示当前操作的菜单
const copyToggleExpand = (source, id) => {
    let target = { ...source };
    if (source.id === id) {
        target.isExpand = !source.isExpand;
    }
    if (source.children !== undefined && source.children !== null && source.children.length > 0) {
        target.children = source.children.map(sub => copyToggleExpand(sub, id));
    }
    return target;
}

// 复制并收拢所有所有菜单
const copyCollapseAllMenus = (source) => {
    let target = { ...source, isExpand: false };
    if (source.children !== undefined && source.children !== null && source.children.length > 0) {
        target.children = source.children.map(sub => copyCollapseAllMenus(sub));
    }
    return target;
}

// 复制并根据路径设置当前选中菜单
const copyPathChangeMenu = (source, id) => {
    let target = { ...source, isActive: false, isExpand: false };
    if (source.id === id) {
        target.isActive = true;
    }
    if (source.children !== undefined && source.children !== null && source.children.length > 0) {
        target.children = source.children.map(sub => copyPathChangeMenu(sub, id));
    }
    return target;
}

// 复制并展开选中的菜单
const copySelectMenu = (source, id) => {
    let target = { ...source, isActive: false };
    if (source.id === id) {
        target.isExpand = !target.isExpand;
        target.isActive = true;
    }

    if (source.children !== undefined && source.children !== null && source.children.length > 0) {
        target.children = source.children.map(sub => copySelectMenu(sub, id));
    }

    return target;
}

// 根据路径获取菜单id
const getIdByPath = (menus, path) => {
    let id = 0;
    let matchMenus = [];
    getMatchMenus(menus, path, matchMenus);
    if (matchMenus.length > 0) {
        const maxLength = Math.max(...matchMenus.map(x => x.url.length));   // 最长匹配的菜单，就置为当前选中的菜单
        id = matchMenus.find(x => x.url.length === maxLength).id;
    }
    return id;
}

const getMatchMenus = (menus, path, matchMenus) => {
    for (let x of menus) {
        if (x.children !== undefined && x.children !== null && x.children.length > 0) {
            getMatchMenus(x.children, path, matchMenus);
        }
        let url = x.url;
        let index = url.indexOf("?");
        if (index > 0) {
            url = url.substring(0, index);
        }
        if (path.indexOf(url) === 0) {
            matchMenus.push(x);
            break;
        }
    }
}

// 展开当前菜单所有的父菜单
const expandParentMenu = (originMenus, levelMenus, id) => {
    // 循环当前层级菜单
    for (let menu of levelMenus) {
        if (menu.id === id) {
            menu.isExpand = true;
            if (menu.parentId > 0) {
                expandParentMenu(originMenus, originMenus, menu.parentId);
            }
            return;
        }
    }

    // 循环子菜单
    for (let menu of levelMenus) {
        if (menu.children !== undefined && menu.children !== null && menu.children.length > 0) {
            expandParentMenu(originMenus, menu.children, id);
        }
    }
}