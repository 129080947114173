import React, { lazy, Suspense } from 'react';

const DevelopsRoute = lazy(() => import('../../../pages/develops/routes'));
const SalesRoute = lazy(() => import('../../../pages/sales/routes'));
const ProgressRoute = lazy(() => import('../../../pages/progressNews/routes'));
const BizConfigRoute = lazy(() => import('../../../pages/bizConfig/routes'));
const PurchaseRoute = lazy(() => import('../../../pages/purchases/routes'));
const ProduceRoute = lazy(() => import('../../../pages/produces/routes'));
const NewProduceRoute = lazy(() => import('../../../pages/produceInstructs/routes'));
const QcRoute = lazy(() => import('../../../pages/qcs/routes'));
const DeliverRoute = lazy(() => import('../../../pages/delivers/routes'));
const ReturnRoute = lazy(() => import('../../../pages/returns/routes'));
const BulkRoute = lazy(() => import('../../../pages/bulks/routes'));
const SpotRoute = lazy(() => import('../../../pages/spots/routes'));
const RetailRoute = lazy(() => import('../../../pages/retails/routes'));
const WarehouseRoute = lazy(() => import('../../../pages/warehouses/routes'));
const FinanceRoute = lazy(() => import('../../../pages/finances/routes'));
const SuperAdminRoute = lazy(() => import('../../../pages/superAdmins/routes'));
const PublicPageRoute = lazy(() => import('../../../pages/publicPages/routes'));
const AnalysisRoute = lazy(() => import('../../../pages/analysis/routes'));


const ContentRoute = () => {
    return (
        <div className="fullWidth" style={{ height: "100%" }}>
            <Suspense fallback={<div>Loading...</div>}>
                <DevelopsRoute />
                <SalesRoute />
                <ProgressRoute />
                <BizConfigRoute />
                <PurchaseRoute />
                <ProduceRoute />
                <NewProduceRoute />
                <QcRoute />
                <DeliverRoute />
                <ReturnRoute />
                <BulkRoute />
                <SpotRoute />
                <RetailRoute />
                <WarehouseRoute />
                <FinanceRoute />
                <SuperAdminRoute />
                <PublicPageRoute />
                <AnalysisRoute />
            </Suspense>
        </div>
    );
}

export default ContentRoute;