import { createStore, combineReducers } from 'redux';
import reducerRegistry from './reducerRegistry';

// Preserve initial state for not-yet-loaded reducers
const combine = (reducers) => {
    return combineReducers(reducers);
};

const reducer = combine(reducerRegistry.getReducers());
const store = createStore(reducer);

// Replace the store's reducer whenever a new reducer is registered.
// 该事件会在有动态reducer注入到store时触发
reducerRegistry.setChangeListener(reducers => {
    store.replaceReducer(combine(reducers));
});

export default store;