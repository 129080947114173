import * as actionTypes from './actionTypes';

// 收拢所有菜单
export const collapseAllMenu = () => ({
    type: actionTypes.Common_Menu_CollapseAll,
});

// 选中某个菜单
export const selectMenu = (id) => ({
    type: actionTypes.Common_Menu_SelectMenu,
    data: {
        id: id,     // 当前选中的菜单id
    },
});

export const pathChange = (path)=>({
    type: actionTypes.Common_Menu_PathChange,
    data:{
        path: path,     //当前路径
    },
});

// 初始化菜单
export const initMenu = () => ({
    type: actionTypes.Common_Menu_Init,
});

// 展开/收拢某个菜单
export const toggleExpand = (id) => ({
    type: actionTypes.Common_Menu_ToggleExpand,
    data: {
        id: id,
    },
});