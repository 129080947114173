import React, { Component } from 'react';
import './index.css';
import { Button } from "@blueprintjs/core";
import apiTool from '../../../utils/apiTool';
import cookieUtil from '../../../utils/cookieUtil';
import { Icon } from "@blueprintjs/core";
import ServiceExpire from '../../companyConfigs/companyExpires/ServiceExpire';

class Login extends Component {
    constructor() {
        super(...arguments);

        this.onKeyDown = this.onKeyDown.bind(this);
        this.getVerifyCode = this.getVerifyCode.bind(this);

        this.state = {
            userName: '',
            pwd: '',
            verifyCode: '',

            // 验证码
            imgContent: null,
            originVerifyCode: '',

            isExpire: false,
        }

        this.init();
    }

    componentDidMount() {
        // 点击键盘enter时，提交码单
        document.addEventListener('keypress', this.onKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keypress", this.onKeyDown)
    }

    init() {
        // 检查公司服务是否已过期
        apiTool.get('/api/Login/CheckCompanyExpire', (data) => {
            if (data.isExpire) {
                this.setState({ isExpire: true });
                return;
            }

            this.getVerifyCode();
        });
    }

    // Enter按钮，登录
    onKeyDown(e) {
        if (e.key === 'Enter') {
            this.onClick();
        }
    }

    // 获取验证码
    getVerifyCode() {
        apiTool.get('/api/Login/GetVerifyCode', (data) => {
            this.setState({ imgContent: data.imgContent });
            this.setState({ originVerifyCode: data.verifyCode });
        })
    }

    onValueChange(name, value) {
        if (name === 'userName') {
            this.setState({ userName: value });
        }
        else if (name === 'pwd') {
            this.setState({ pwd: value });
        }
        else if (name === 'verifyCode') {
            this.setState({ verifyCode: value });
        }
    }

    onClick() {
        var request = {
            userName: this.state.userName,
            pwd: this.state.pwd,
            verifyCode: this.state.verifyCode,
            originVerifyCode: this.state.originVerifyCode,
        };
        apiTool.post('/api/Login/Log', request, (data) => {
            var code = data.code;
            if (code === -100) {
                alert(data.message);
                // 刷新验证码，清空填写的验证码
                this.setState({ verifyCode: '' });
                this.getVerifyCode();
            } else if (code === -110) {
                cookieUtil.set('_mytoken_', data.token, 1);

                // 获取菜单
                apiTool.get('api/Login/GetMenu', (data) => {
                    // 将菜单保存到localstorage
                    localStorage.setItem('menu', JSON.stringify(data.menus));

                    // 获取页面头部信息
                    apiTool.get('api/Login/GetHead', (data) => {
                        localStorage.setItem('user', JSON.stringify(data.user));
                        localStorage.setItem('company', JSON.stringify(data.company));

                        // 跳转到默认页面
                        window.location = '/bizconfig/products/fabric';
                    });
                });
            } else if (code === -200) {   // 服务已过期
                cookieUtil.set('_mytoken_', '', 1);
                // 跳转到服务过期页面
                window.location = '/';
            }
        }, (err) => {
            console.log(err);
        });
    }

    render() {
        // 服务是否已过期
        if (this.state.isExpire) {
            return (
                <ServiceExpire />
            );
        }

        return (
            <div className="login-container">
                <div className="login-content">
                    {/* 用户名，密码 */}
                    <div className="login-user-container">
                        <table className="login-user-tb">
                            <tbody>
                                <tr>
                                    <td className="login-user-tb-first">
                                        <Icon
                                            className=""
                                            icon={'person'} />
                                    </td>
                                    <td className="login-user-tb-second">
                                        <input
                                            type="text"
                                            value={this.state.userName}
                                            onChange={(e) => this.onValueChange('userName', e.target.value)}
                                            placeholder="账号"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="login-user-tb-first">
                                        <Icon
                                            className=""
                                            icon={'lock'} />
                                    </td>
                                    <td className="login-user-tb-second">
                                        <input
                                            type="password"
                                            value={this.state.pwd}
                                            onChange={(e) => this.onValueChange('pwd', e.target.value)}
                                            placeholder="密码"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {/* 验证码 */}
                    <div>
                        <table className="login-ma-tb">
                            <tbody>
                                <tr>
                                    <td className="login-ma-tb-first"></td>
                                    <td className="login-ma-tb-second">
                                        <input
                                            type="text"
                                            value={this.state.verifyCode}
                                            onChange={(e) => this.onValueChange('verifyCode', e.target.value)}
                                            placeholder="验证码"
                                        />
                                    </td>
                                    <td className="login-ma-tb-third">
                                        <img src={'data:image/png;base64,' + this.state.imgContent} onClick={this.getVerifyCode} alt="" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {/* 登录按钮 */}
                    <div>
                        <Button
                            onClick={() => this.onClick()}
                            intent={'warning'}
                            className="bp3-button login-button">登&nbsp;&nbsp;&nbsp;录</Button>
                    </div>
                    <div className="login-logo">
                        布先锋®
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;