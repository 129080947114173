import React, { Component } from 'react';

class ServiceExpire extends Component {
    constructor() {
        super(...arguments);
    }

    render() {
        return (
            <div>
                贵公司的服务已到期，请联系布先锋销售人员!
            </div>
        );
    }
}

export default ServiceExpire;