import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { Layout } from './components/layout';
import store from './store/store.js';
import cookieUtil from './utils/cookieUtil';
import Login from './pages/logins/views';

// const routes = () => (
//     <Provider store={store}>
//         <BrowserRouter>
//             <Layout />
//         </BrowserRouter>
//     </Provider>
// );

class Routes extends Component {

    render() {

        // 从cookie获取登录token
        var token = cookieUtil.get('_mytoken_');

        if (!token || token.length === 0) {
            return (
                <Provider store={store}>
                    <BrowserRouter>
                        <Login />
                    </BrowserRouter>
                </Provider>
            );
        }

        return (

            <Provider store={store}>
                <BrowserRouter>
                    <Layout />
                </BrowserRouter>
            </Provider>
        );
    }
}

export default Routes;