import React, { Component } from 'react';
import './style.css';
import { Menu } from '../../menu/';
import { ContentRoute } from '../../contentRoute';
import MyHead from '../../myHead/views';

import imgProcess from '../../../process.gif';


export default class Layout extends Component {

    render() {
        return (
            <div className="layout-root">

                {/* 处理中 */}
                <div id="layout-content-process" className="layout-content-process-container">
                    <img src={imgProcess} alt="" />
                    <div className="layout-content-process-tips">处理中。。。</div>
                </div>

                <div className="layout-header-container">
                    <MyHead />
                </div>
                <div className="layout-body-container">
                    <div className="layout-body">
                        <div className="layout-menu-container">
                            <Menu />
                        </div>
                        <div className="layout-content-container">
                            <ContentRoute />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}