import React from 'react';
import { Dialog } from "@blueprintjs/core";
import './index.css';
import { connect } from 'react-redux';

class MyDialog extends React.Component {
    constructor() {
        super(...arguments);
        this.closeDialog = this.closeDialog.bind(this);
        this.state = { isOpen: this.props.isOpen };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ isOpen: nextProps.isOpen });
    }

    render() {
        const body = this.props.body;
        const footer = this.props.footer;
        const dialogWidth = this.props.width ? this.props.width : "60%";
        const dialogHeight = this.props.height ? this.props.height : (window.innerHeight - 100);

        return (
            <Dialog
                title={this.props.title}
                style={{ width: dialogWidth, height: dialogHeight }}
                isOpen={this.state.isOpen}
                onClose={this.closeDialog}
                canOutsideClickClose={false}
                canEscapeKeyClose={false}
            >
                <div className="c-d-container">
                    <div className="c-d-body">
                        {body}
                    </div>
                    {footer && (
                        <div className="c-d-footer">
                            {footer}
                        </div>
                    )}
                </div>
            </Dialog>
        );
    }

    // 事件
    closeDialog() {
        // 注意，onClose优先级高，且onClose与onCloseWithConfirm只能有一个方法生效
        if (this.props.onClose !== null && typeof (this.props.onClose) === 'function') {
            this.setState({ isOpen: false });
            this.props.onClose();
        } else if (this.props.onCloseWithConfirm !== null && typeof (this.props.onCloseWithConfirm) === 'function') {
            let isClose = this.props.onCloseWithConfirm();
            if (isClose) {
                this.setState({ isOpen: false });
            }
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        body: ownProps.body,
        footer: ownProps.footer,
        title: ownProps.title,
        width: ownProps.width,
        height: ownProps.height,
        isOpen: ownProps.isOpen,
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onClose: ownProps.onClose,
        // () => 
        //  {
        //     if (typeof ownProps.onClose === "function") {
        //         ownProps.onClose();
        //     }
        // },
        onCloseWithConfirm: ownProps.onCloseWithConfirm,
        // () => {
        //     if (typeof ownProps.onCloseWithConfirm === "function") {
        //         ownProps.onCloseWithConfirm();
        //     }
        // }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyDialog);