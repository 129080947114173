const cookieUtil = {

    // 取cookie
    get: (name) => {
        if (document.cookie.length > 0) {
            var c_start = document.cookie.indexOf(name + "=");//获取字符串的起点
            if (c_start !== -1) {
                c_start = c_start + name.length + 1;//获取值的起点
                var c_end = document.cookie.indexOf(";", c_start);//获取结尾处
                if (c_end === -1) c_end = document.cookie.length;//如果是最后一个，结尾就是cookie字符串的结尾
                return decodeURIComponent(document.cookie.substring(c_start, c_end));//截取字符串返回
            }
        }

        return '';
    },

    // 设置cookie
    set: (name, value, expiredays) => {
        var exdate = new Date();
        const oneDayLength = 86400000;      // 1天的毫秒数
        exdate.setTime(Number(exdate) + oneDayLength * expiredays);
        document.cookie = name + "=" + encodeURIComponent(value) + ";path=/" + ((expiredays === null) ? "" : ";expires=" + exdate.toGMTString());
    },

    // 删除cookie
    delete: (name) => {
        document.cookie = name + '=;path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },

    // 延长过期时间
    extendExpire: (name, extendDays) =>{
        var value = cookieUtil.get(name);
        cookieUtil.set(name, value, extendDays);
    },

};

export default cookieUtil;