const processUtil = {

    // 处理中逻辑：
    // 该元素位于layout.js中，id是layout-content-process
    // 默认隐藏
    // 当调用服务端超过2秒还未完成时，显示处理中控件，提醒用户

    innerState: {
        processApis: [],     // 正在处理的api集合
        elementId: 'layout-content-process',    // 【处理中】的元素id
    },

    // 展示处理中
    show(api) {
        let st = setTimeout(() => {
            processUtil._show();
        }, 2000);       // 2秒后如果无响应，显示处理中。。。
        processUtil.innerState.processApis.push({ api: api, st: st });
    },

    // 关闭处理中
    close(api) {
        let index = processUtil.innerState.processApis.findIndex(x => x.api === api);
        if (index > -1) {
            let st = processUtil.innerState.processApis[index].st;
            clearTimeout(st);
            processUtil.innerState.processApis.splice(index, 1);
        }
        if (processUtil.innerState.processApis.length === 0) {      // 如果没有处理中的api，就隐藏【处理中】元素
            processUtil._close();
        }
    },


    _show() {
        // 注意：处理中组件位于layout组件中，默认是隐藏的
        let processEle = document.getElementById(processUtil.innerState.elementId);
        if (processEle !== null) {
            processEle.style.visibility = 'visible';

            // todo
            // 可在此处埋点，监控长时间的服务
        }
    },

    _close() {
        let processEle = document.getElementById(processUtil.innerState.elementId);
        if (processEle !== null) {
            processEle.style.visibility = 'hidden';
        }
    }
};

export default processUtil;