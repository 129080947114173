import { Position, Toaster, Intent } from "@blueprintjs/core";
import ReactHtmlParser from 'react-html-parser';

/** Singleton toaster instance. Create separate instances for different options. */
const AppToaster = Toaster.create({
    className: "recipe-toaster",
    position: Position.TOP,
});

const Remind = (message) => {
    AppToaster.show({
        message: message,
        timeout: 1200,
        intent: Intent.SUCCESS
    });
}

const Warning = (message) => {
    AppToaster.show({
        message: ReactHtmlParser(message),
        timeout: 10000,
        intent: Intent.WARNING
    });
}

export { Remind, Warning };