import axios from "axios";
import { Remind, Warning } from '../components/remind';
import processUtil from "./processUtil";
import cookieUtil from "./cookieUtil";

const fileUploadApi = '/api/uploadfile';

const apiTool = {
    get: (api, callback, exCallback) => {
        axios.get(api, { headers: { '_mytoken_': cookieUtil.get('_mytoken_') } }).then(res => {
            if (res.data.code === -100) {
                cookieUtil.delete('_mytoken_');
                window.location = '/';      // 跳转到登录页面
                return;
            } else if (res.data.code === -200) {    // 服务已过期
                cookieUtil.delete('_mytoken_');
                // 跳转到服务过期页面
                window.location = '/';
                return;
            }

            if (typeof callback === "function") {
                callback(res.data);
            }

            // 每次操作延长token过期时间
            cookieUtil.extendExpire('_mytoken_', 3);    // 3天后过期
        }).catch(err => {
            if (typeof exCallback === "function") {
                exCallback(err);
            }
        })
    },


    post: (api, req, callback, exCallback, warningCallback) => {
        apiTool._post(api, req, callback, exCallback, warningCallback, true, true);
    },
    postWithoutLoading: (api, req, callback, exCallback, warningCallback) => {
        apiTool._post(api, req, callback, exCallback, warningCallback, false, true);
    },
    postWithoutMessage: (api, req, callback, exCallback, warningCallback) => {
        apiTool._post(api, req, callback, exCallback, warningCallback, true, false);
    },
    _post: (api, req, callback, exCallback, warningCallback, needLoading, showMessage) => {

        if (needLoading) {
            processUtil.show(api);
        }

        axios.post(api, req, { headers: { '_mytoken_': cookieUtil.get('_mytoken_') } }).then(res => {

            if (needLoading) {
                processUtil.close(api);
            }

            if (res.data.code === 0) {

            } else if (res.data.code === 1) {  // 1表示执行成功，并且需要在页面显示提醒信息
                if (showMessage) {
                    if (res.data.message !== undefined && res.data.message !== null && res.data.message.length > 0) {
                        Remind(res.data.message);
                    } else {
                        Remind("执行成功");
                    }
                }
            } else if (res.data.code === -1) {
                if (typeof warningCallback === "function") {
                    warningCallback(res.data.message);
                } else {
                    Warning(res.data.message);
                }
                return;
            } else if (res.data.code === -101) {    // 普通api验证失败，不做任何提示，直接跳转到登录页面
                cookieUtil.delete('_mytoken_');
                window.location = '/';      // 跳转到登录页面
                return;
            } else if (res.data.code === -200 || res.data.code === -201 || res.data.code === -202) {    // 公司已删除或已到期
                cookieUtil.delete('_mytoken_');
                // 跳转到服务过期页面
                window.location = '/';
                return;
            } else if (res.data.code === -300 || res.data.code === -301) {      // 用户已删除或已失效
                cookieUtil.delete('_mytoken_');
                window.location = '/';      // 跳转到登录页面
                return;
            }

            if (typeof callback === "function") {
                callback(res.data);
            }

            // 每次操作延长token过期时间
            cookieUtil.extendExpire('_mytoken_', 3);        // 3天后过期
        }).catch(err => {

            if (needLoading) {
                processUtil.close(api);
            }

            if (typeof exCallback === "function") {
                exCallback(err);
            } else {        // 无异常回调函数，直接显示错误信息
                alert(err);
            }
        });
    },


    uploadFile: (formData, callback, exCallback) => {
        var file = formData.get("file");
        if (file === undefined) {
            return;
        }
        if (file.size > 1024 * 1024) {
            alert("文件不能超过1M");
            return false;
        }

        processUtil.show(fileUploadApi);

        axios.post(fileUploadApi, formData, {
            headers: { 'Content-Type': 'multipart/form-data', '_mytoken_': cookieUtil.get('_mytoken_') }
        }).then(res => {
            processUtil.close(fileUploadApi);
            if (typeof callback === "function") {
                callback(res.data);
            }
        }).catch(err => {
            processUtil.close(fileUploadApi);
            if (typeof exCallback === "function") {
                exCallback(err);
            }
        })
    },
    importExcel: (formData, api, callback, exCallback) => {
        var file = formData.get("file");
        if (file === undefined) {
            return;
        }
        // 下面实际判断的是100M，由于通过粘贴板复制后的图片大小比保存到本地的图片大约5-10倍，所以将判断10M大小的值改成100M
        if (file.size > 100 * 1024 * 1024) {
            alert("文件不能超过10M");
            return false;
        }

        processUtil.show(api);

        axios.post(api, formData, {
            headers: { 'Content-Type': 'multipart/form-data', '_mytoken_': cookieUtil.get('_mytoken_') }
        }).then(res => {
            processUtil.close(api);
            if (typeof callback === "function") {
                callback(res.data);
            }
        }).catch(err => {
            processUtil.close(api);
            if (typeof exCallback === "function") {
                exCallback(err);
            }
        })
    },
    downloadFile: (api) => {
        let token = encodeURIComponent(cookieUtil.get('_mytoken_'));        // 浏览器地址栏会自动调一次url解码
        api = axios.defaults.baseURL + api + "&iomxp=" + token;
        window.open(api, '_blank');     // 直接在新窗口打开，就会下载文件
    },
};

export default apiTool;