import React, { Component } from 'react';
import { connect } from 'react-redux';
import './style.css';
import { Icon, Intent } from "@blueprintjs/core";
import { actions as menuActions } from '../';
import { Link, withRouter } from "react-router-dom";

class Menu extends Component {
    constructor() {
        super(...arguments);

        this.props.onPathChange(this.props.location.pathname);

        this.state = {
            menus: this.props.menus,
            expandIconClicked: false,
        };
    }

    // 收拢所有菜单
    collapseAll() {
        this.props.onMenuCollapseAll();
    }

    // 生成icon
    genIcon(menu) {
        // 只有第一层菜单才需要图标
        if (menu.level !== 1) {
            return "";
        }

        return (
            <Icon
                className="menu-item-icon menu-item-icon-info global-icon"
                icon={menu.icon}
                iconSize={Icon.SIZE_STANDARD}
                intent={Intent.NONE} />);
    }

    // 生成展开/收拢的icon
    genExpandIcon(menu) {
        let iconName = "";
        if (menu.children !== undefined && menu.children !== null && menu.children.length > 0) {
            iconName = menu.isExpand ? "chevron-down" : "chevron-right";
        }
        return (
            <Icon
                className="menu-item-icon"
                icon={iconName}
                iconSize={Icon.SIZE_STANDARD} intent={Intent.NONE}
                onClick={(e) => this.toggleExpandHandler(e, menu.id)} />
        );
    }

    // 生成菜单内容
    genMenuContent(menu) {
        let className = "menu-item-content";
        if (menu.level === 1) {
            className += " menu-item-top";
        }
        let marginLeft;
        if (menu.level === 1) {
            marginLeft = 0;
        } else if (menu.level === 2) {
            marginLeft = 21;
        } else {
            marginLeft = 21 + (menu.level - 1) * 8;
        }

        return (
            <div style={{ marginLeft: marginLeft }} className={className}>
                {this.genExpandIcon(menu)}
                {this.genIcon(menu)}
                <span style={{ wordWrap: "nowrap" }}>{menu.title}</span>
            </div>
        );
    }

    // 生成一层所有菜单
    genLevelMenus(levelMenus) {
        if (levelMenus === undefined || levelMenus === null || levelMenus.length === 0) {
            return "";
        }

        return (
            <ul className="clear-li">
                {levelMenus.map(menu => {

                    let className = "menu-item-container";
                    if (menu.isActive) {
                        className += " menu-content-active";
                    }

                    return (
                        <li key={menu.id}>
                            <Link to={menu.url}>
                                <div className={className} onClick={() => this.selectMenuHandler(menu.id)}>
                                    {this.genMenuContent(menu)}
                                </div>
                            </Link>
                            {menu.isExpand && menu.children !== undefined && menu.children !== null && menu.children.length > 0 &&
                                this.genLevelMenus(menu.children)
                            }
                            {menu.level === 1 && (
                                <div className="menu-item-group-spliter"></div>
                            )}
                        </li>
                    )
                }
                )}
            </ul>);
    }

    render() {
        return (
            <div className="menu-container">
                <div className="menu-collapse-container" title="全部折叠">
                    <Icon
                        className="menu-collapse"
                        icon={"expand-all"}
                        iconSize={Icon.SIZE_STANDARD} intent={Intent.NONE}
                        onClick={() => this.collapseAll()} />
                </div>
                {this.genLevelMenus(this.props.menus)}
            </div>
        );
    }

    //#region 事件处理

    selectMenuHandler(id) {
        if (this.state.expandIconClicked) {
            this.state.expandIconClicked = false;
            return;
        }
        this.props.onMenuSelect(id);
    }

    // 展开/收拢菜单
    toggleExpandHandler(e, id) {
        this.state.expandIconClicked = true;
        this.props.onMenuToggleExpand(id);
        e.preventDefault();
    }

    //#endregion
}

const mapStateToProps = (state) => {
    const menus = state.menuReducer.menus;
    return {
        menus: menus,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onMenuToggleExpand: (id) => {
            dispatch(menuActions.toggleExpand(id));
        },
        onMenuCollapseAll: () => {
            dispatch(menuActions.collapseAllMenu());
        },
        onMenuSelect: (id) => {
            dispatch(menuActions.selectMenu(id));
        },
        onPathChange: (path) => {
            dispatch(menuActions.pathChange(path));
        }
    }
};

const MenuContainer = connect(mapStateToProps, mapDispatchToProps)(Menu);

// 使用路由包装容器，在菜单组件中可以访问路由信息
const MenuContainerWithRouter = withRouter(props => <MenuContainer {...props} />)

export default MenuContainerWithRouter;

