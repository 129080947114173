import React, { Component } from 'react';
import './index.css';
import cookieUtil from '../../../utils/cookieUtil';
import { MyDialog } from '../../myDialog';
import { Button } from "@blueprintjs/core";
import apiTool from '../../../utils/apiTool';
import imgUrl from './布先锋logo.png';

class MyHead extends Component {

    constructor() {
        super(...arguments);

        this.state = {
            // 修改密码
            mp_isOpen: false,
            mp_fields: {
                oldPwd: '',
                newPwd: '',
                confirmPwd: '',
            },
        }
    }

    onClick(name) {
        if (name === 'exit') {
            cookieUtil.delete('_mytoken_');     // 清空token
            window.location = '/';       // 跳转到登录页
        } else if (name === 'modifypwd') {
            // 弹出修改密码对话框
            this.setState({
                mp_fields: {
                    oldPwd: '',
                    newPwd: '',
                    confirmPwd: '',
                }
            });
            this.setState({ mp_isOpen: true });
        }
    }

    // 修改密码
    onButtonClick(name) {
        if (name === 'save') {

            // 数据检查
            if (this.state.mp_fields.newPwd !== this.state.mp_fields.confirmPwd) {
                alert('新密码和密码确认不相等');
                return;
            }

            var request = { OldPwd: this.state.mp_fields.oldPwd, NewPwd: this.state.mp_fields.newPwd };
            apiTool.post('/api/Login/ModifyPwd', request, (data) => {
                this.setState({ mp_isOpen: false });
            });
        }
    }

    onClose_mp() {
        this.setState({ mp_isOpen: false });
    }

    onValueChange_mp(name, value) {
        if (name === 'oldPwd') {
            this.setState({ mp_fields: { ...this.state.mp_fields, oldPwd: value } });
        }
        else if (name === 'newPwd') {
            this.setState({ mp_fields: { ...this.state.mp_fields, newPwd: value } });
        }
        else if (name === 'confirmPwd') {
            this.setState({ mp_fields: { ...this.state.mp_fields, confirmPwd: value } });
        }
    }

    // html
    genModifyPwd() {
        return (
            <MyDialog
                isOpen={this.state.mp_isOpen}
                title={'修改密码'}
                height={240}
                width={400}
                onClose={() => this.onClose_mp()}
                body={
                    <div className="myhead-mp-container">
                        <table className="myhead-mp-tb">
                            <tbody>
                                <tr>
                                    <td>原密码：</td>
                                    <td><input
                                        type="password"
                                        value={this.state.mp_fields.oldPwd}
                                        onChange={(e) => this.onValueChange_mp('oldPwd', e.target.value)}
                                    /></td>
                                </tr>
                                <tr>
                                    <td>新密码：</td>
                                    <td><input
                                        type="password" value={this.state.mp_fields.newPwd}
                                        onChange={(e) => this.onValueChange_mp('newPwd', e.target.value)}
                                    /></td>
                                </tr>
                                <tr>
                                    <td>密码确认：</td>
                                    <td><input
                                        type="password" value={this.state.mp_fields.confirmPwd}
                                        onChange={(e) => this.onValueChange_mp('confirmPwd', e.target.value)}
                                    /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                }
                footer={
                    <div>
                        <Button
                            onClick={() => this.onButtonClick('save')}
                            intent={'success'}
                            className="bp3-button dialogButton">提交</Button>
                    </div>
                }
            >
            </MyDialog>
        );
    }

    render() {

        var user = JSON.parse(localStorage.getItem('user'));
        var company = JSON.parse(localStorage.getItem('company'));


        return (
            <div className="myhead-container">
                {/* 软件商标 */}
                <div className="myhead-remark">
                    <img src={imgUrl} alt="" />
                </div>

                {/* 公司名称 */}
                <div className="myhead-company">{company.companyName}</div>

                {/* 用户信息 */}
                <div className="myhead-user">
                    <div className="myHead-user-name">
                        {user.chineseName}
                    </div>
                    <div>
                        <a onClick={() => this.onClick('exit')} id="myhead-exit" style={{ marginRight: 4 }}>退出</a>|
                        <a onClick={() => this.onClick('modifypwd')} style={{ marginLeft: 4 }}>修改密码</a>
                    </div>
                </div>

                {this.genModifyPwd()}
            </div>
        );
    }
}

export default MyHead;